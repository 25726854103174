import './ProductTile.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { Button, ButtonVariant } from './Button';
import { t } from './i18n/i18n';
import { IconName, IconSize } from './Icon';

export type ProductTileProps = b2x.ProductTileProps;

export const ProductTile = (props: ProductTileProps) => {
  return <b2x.ProductTile className={'card border-0 my-3'} {...props} />;
};

export type ProductTileBasicInfoProps = b2x.ProductTileBasicInfoProps;

export const ProductTileBasicInfo = ({
  handleLinkClick,
  imageFormat,
  product,
  productsPerRow,
  sku,
}: ProductTileBasicInfoProps) => {
  const { discountPercentage } = b2x.usePrice(product.priceRange, sku?.price);

  const selectedSku = React.useMemo(
    () => product.skus?.find((productSku) => productSku.id === sku?.id),
    [product.skus, sku]
  );

  return (
    <div className="product-tile-basic-info">
      <div className="product-tile-image-container position-relative mb-3 border rounded">
        <b2x.router.Link onClick={handleLinkClick} style={{ display: 'block' }} to={product.url}>
          <b2x.EqualHeightElement name={'product-tile-image'}>
            <b2x.Image
              alt={product.name}
              aspectRatio={b2x.appConfig.productImageAspectRatio}
              className="rounded d-block"
              fluid
              format={imageFormat}
              src={selectedSku?.image ? selectedSku.image.src : product.image?.src}
            />
          </b2x.EqualHeightElement>
        </b2x.router.Link>
        {b2x.appConfig.enableWishlist && (
          <div className="wishlist-action-container position-absolute end-0 top-0 py-1 px-2 px-md-3 py-md-2">
            <b2x.WishlistButtonHelper product={product} sku={sku}>
              {({ handleWishlistButtonClick, inWishlist }) => (
                <Button
                  className="text-blue"
                  iconEnd={{ name: inWishlist ? 'wishlist-full' : 'wishlist', size: 25 }}
                  onClick={handleWishlistButtonClick}
                  size="large"
                  type="button"
                  variant="blank"
                />
              )}
            </b2x.WishlistButtonHelper>
          </div>
        )}

        {discountPercentage && (
          <div className="discount-percentage position-absolute bottom-0 start-0 pb-2 pb-md-3">
            <span className="bg-red text-white fw-bold lh-1 pb-1 ps-3 pe-2 rounded-end">{`-${b2x.formatFloatPercentage(
              discountPercentage
            )}%`}</span>
          </div>
        )}

        {product.tags?.includes('GENTILINI_NEW') && (
          <div className="new-product-sticker position-absolute top-0 start-0 py-1 px-2 px-md-3 py-md-2">
            <span className="bg-blue-light text-white text-lowercase extra-small lh-1 badge rounded-pill">
              {t('misc.newProductSticker')}
            </span>
          </div>
        )}
      </div>
      <div className="mb-2">
        <b2x.EqualHeightElement name={`product-title-row-${productsPerRow}`}>
          <h6 className="m-0 mb-1">
            <b2x.router.Link
              className="text-decoration-none text-blue fw-bold"
              onClick={handleLinkClick}
              to={product.url}
            >
              {b2x.formatHtml(product.name)}
            </b2x.router.Link>
          </h6>
          {selectedSku && selectedSku.name !== product.name && (
            <h6 className="small mb-0 text-blue m-0">{selectedSku.name}</h6>
          )}
        </b2x.EqualHeightElement>
      </div>
    </div>
  );
};

export type ProductTileExtraInfoProps = b2x.ProductTileExtraInfoProps;

export const ProductTileExtraInfo = (props: ProductTileExtraInfoProps) => (
  <b2x.ProductTileExtraInfo customComponent={CustomProductTileExtraInfo} {...props} />
);

const CustomProductTileExtraInfo = ({
  fieldsHelper,
  priceHelper,
  product,
  productsPerRow,
  selectedSku,
}: ProductTileExtraInfoProps) => {
  return (
    <div className="product-tile-extra-info mt-1 mt-lg-3">
      <b2x.EqualHeightElement name={`product-tile-variant-${productsPerRow}`}>
        <div>
          {fieldsHelper.productVariants.formFields.length > 1 && (
            <b2x.FormGroup
              {...fieldsHelper.productVariants.formGroup}
              className="product-variant bg-powder-light p-2 pt-1 d-inline-block"
              label={undefined}
            >
              <div>
                <h6 className="small mb-1">{t('form.productForm.size')}</h6>
                <div className="product-variant-container d-flex flex-wrap gap-2 small">
                  {fieldsHelper.productVariants.formFields.map((formField, index) => (
                    <b2x.Radio
                      key={formField.productVariant.id}
                      {...formField.radio}
                      inline
                      labelClassName="fw-bold px-2"
                    >
                      {formField.productVariant.skus?.map((sku, indexSku) => (
                        <React.Fragment key={sku.id}>
                          {indexSku === 0 && sku.measurement?.value && sku.measurement.measurementUnit ? (
                            <span className="text-lowercase">
                              {`${sku.measurement.value} ${sku.measurement.measurementUnit}`}
                            </span>
                          ) : (
                            sku.name
                          )}
                        </React.Fragment>
                      ))}
                    </b2x.Radio>
                  ))}
                </div>
              </div>
            </b2x.FormGroup>
          )}
        </div>
      </b2x.EqualHeightElement>
      <div className="d-flex align-items-center gap-2 gap-md-3">
        <div className="d-none d-lg-inline">
          <b2x.FormGroup {...fieldsHelper.quantity.formGroup} className="text-center" label={undefined} noMarginBottom>
            <b2x.Select
              {...fieldsHelper.quantity.select}
              includeEmptyOption={false}
              placeholder={undefined}
              style={{ maxWidth: '80px' }}
            />
          </b2x.FormGroup>
        </div>

        {priceHelper && (
          <div className="hstack flex-wrap">
            <div className="me-2">
              <b2x.PriceBlock gap={2} priceHelper={priceHelper} />
            </div>

            {b2x.environment !== 'PRODUCTION' && product.assembled === false && priceHelper.perUnit?.price && (
              <div className="extra-small text-blue">
                {`(${t('misc.pricePerUnit', {
                  measurementUnit: priceHelper.perUnit.measurement,
                  price: b2x.formatCurrency(priceHelper.perUnit.price),
                })})`}
              </div>
            )}
          </div>
        )}

        <div className="ms-auto">
          {/* <b2x.Button
            className="add-to-cart-button rounded-circle p-1 p-lg-0"
            iconStart={{ name: 'cart', size: 20 }}
            type={'submit'}
            {...fieldsHelper.buttons.submit}
            label={undefined}
          /> */}
          <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
            fieldsHelper={fieldsHelper}
            selectedSku={selectedSku}
            submitButton={{
              className: 'add-to-cart-button rounded-circle p-1 p-lg-0',
              iconStart: { name: 'cart', size: 20 },
              label: undefined,
            }}
          />
        </div>
      </div>
      {b2x.appConfig.enableBestPrice && priceHelper && (
        <div className="extra-small lh-1 mt-2">
          <b2x.BestPrice priceHelper={priceHelper} />
        </div>
      )}
    </div>
  );
};
