import { ShippingPackApiDto } from '@b2x/storefront-api-js-client/src';

import { CartSku } from './CartSku';
import { Div } from './HTMLElement';

export interface CartPacksProps {
  compactLayout?: boolean;
  packs: Array<ShippingPackApiDto>;
  readOnly?: boolean;
}

export const CartPacks = ({ compactLayout, packs, readOnly }: CartPacksProps) => {
  return (
    <Div background="white" className="cart-packs" marginBottom={3} padding={3}>
      {packs.map((pack) => (
        <Div className="cart-pack" key={pack.warehouseId}>
          {pack.cartSkus?.map((cartSku, index) => (
            <CartSku
              cartSku={cartSku}
              compactLayout={compactLayout}
              index={index}
              key={cartSku.id}
              readOnly={readOnly}
            />
          ))}
        </Div>
      ))}
    </Div>
  );
};
