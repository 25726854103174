import classnames from 'classnames';
import React from 'react';

import { Accordion, AccordionItem } from '../Accordion';
import { AlertFromDto } from '../AlertFromDto';
import { useCartApi } from '../api/useCartApi';
import { useAppContext } from '../AppContext';
import { Button } from '../Button';
import { CartCampaigns } from '../CartCampaigns';
import { CartPacks } from '../CartPacks';
import { Col } from '../Col';
import { appConfig } from '../config';
import { Container } from '../Container';
import { CartShippingProfilesFormHelper } from '../form/CartShippingProfilesForm';
import { TextInput } from '../form/fields/Input';
import { Radio } from '../form/fields/RadioCheck';
import { FormGroup } from '../form/FormGroup';
import { GenericCouponFormHelper } from '../form/GenericCouponForm';
import { GiftCardForm } from '../form/GiftCardForm';
import { InputGroup } from '../form/InputGroup';
import { LocaleForm } from '../form/LocaleForm';
import { PersonalCouponsFormHelper } from '../form/PersonalCouponsForm';
import { FreeShippingThreshold } from '../FreeShippingThreshold';
import { Div, H3, H5, H6, P, Span } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { Icon } from '../Icon';
import { InstallmentPaymentBanner } from '../InstallmentPaymentBanner';
import { OffersList } from '../OffersList';
import { PaymentIcons } from '../PaymentIcons';
import { Link } from '../router/Link';
import { Row } from '../Row';
import { useCart } from '../useCart';
import { useModals } from '../useModals';
import { formatCurrency, formatHtml, renderUI } from '../util';
import { CartPageProps } from './CartPage';

export const CartPageA = ({
  cartSkusToMoveCampaignsCodes,
  enableContinueShopping = false,
  freeShippingThresholdCriterion = 'currency',
  summaryTopInfo,
}: CartPageProps) => {
  const { blockingAlerts, campaignsCartSkus, canMoveOn, cart } = useCart({
    cartSkusToMoveCampaignsCodes,
    populate: {
      alerts: true,
      availableCoupons: true,
      billingAddress: true,
      campaigns: true,
      depositPayments: true,
      offers: true,
      packs: {
        alerts: true,
        cartSkus: {
          alerts: true,
          assembledComponents: {
            alerts: true,
            sku: {
              product: true,
            },
          },
          giftCard: true,
          offers: true,
          sku: {
            attributes: true,
            product: {
              attributes: true,
            },
          },
        },
      },
      paymentMethod: true,
      shippingAddress: true,
      shippingProfile: true,
    },
  });

  const { info, shippingCountry } = useAppContext();

  const { removeCoupon, removeGiftCard } = useCartApi();

  const { showModal } = useModals();

  const handleChangeShippingCountryButtonClick = React.useCallback(
    () =>
      showModal({
        children: <ChangeShippingCountryModal />,
        title: t('cart.modal.shippingCountry.title'),
      }),
    [showModal]
  );

  return renderUI({
    bs5: (
      <Container fluid="xxl">
        <Row>
          {cart?.itemsNumber && cart.itemsNumber > 0 ? (
            <>
              <Col size={{ lg: 8, xs: 12 }}>
                <>
                  {cart.packs && <CartPacks packs={cart.packs} />}
                  {cart.campaigns && <CartCampaigns campaigns={cart.campaigns} campaignsCartSkus={campaignsCartSkus} />}
                </>
                <Div
                  background="white"
                  className="cart-footer"
                  display={{ md: 'block', xs: 'none' }}
                  marginBottom={3}
                  padding={3}
                >
                  <H6>{t('cart.paymentMethods.title')}</H6>
                  <PaymentIcons />
                </Div>
              </Col>
              <Col size={{ lg: 4, xs: 12 }}>
                <Div className="cart-summary">
                  {summaryTopInfo && <Div className="summary-top-info">{summaryTopInfo}</Div>}
                  <FreeShippingThreshold
                    cart={cart}
                    enableContinueShopping={enableContinueShopping}
                    freeShippingThresholdCriterion={freeShippingThresholdCriterion}
                  />
                  <Div background="white" marginBottom={3} padding={3}>
                    <H5 paddingBottom={1}>{t('cart.summary.title')}</H5>
                    {cart.totalLoyaltyPoints > 0 && (
                      <Div className="cart-loyalty-points" marginBottom={3}>
                        <Div display={'flex'} gap={2}>
                          {appConfig.icons?.loyalty && (
                            <div className="cart-loyalty-icon">
                              <Icon name={appConfig.icons.loyalty} />
                            </div>
                          )}
                          <div className="cart-loyalty-message">
                            {formatHtml(
                              t('cart.loyaltyPointsEarned', { loyaltyPointsEarned: cart.totalLoyaltyPoints })
                            )}
                          </div>
                        </Div>
                      </Div>
                    )}
                    {!!cart.offers?.length && (
                      <Div className="cart-subtotal" marginBottom={3} marginTop={3}>
                        <OffersList offers={cart.offers.filter((offer) => !offer.showOnCartSku)} />
                      </Div>
                    )}
                    <Div className="cart-coupon" marginBottom={3}>
                      <Accordion id="coupon-accordion" itemButtonClassName="bg-transparent text-black" withoutXPadding>
                        <AccordionItem id="One" show title={t('cart.summary.coupons.title')}>
                          {cart.availableCoupons && (
                            <PersonalCouponsFormHelper appliedCoupon={cart.coupon} disableRemoveCouponRadio>
                              {({ fieldsHelper }) => (
                                <FormGroup {...fieldsHelper.code.formGroup} label={undefined}>
                                  {fieldsHelper.code.formFields.map(({ coupon, radio }) => (
                                    <Div display="flex" justifyContent={'spaceBetween'} key={radio.id}>
                                      <Div alignItems={'start'} display="flex" flexDirection={'row'}>
                                        <Radio
                                          className="pe-1"
                                          {...radio}
                                          label={
                                            <Div uiClassName={{ bs5: 'lh-sm' }}>
                                              <small>
                                                <Span dynamicPadding={{ end: 3 }}>{coupon?.code} </Span>
                                                <Span>{coupon?.description}</Span>
                                              </small>
                                            </Div>
                                          }
                                        />
                                        {cart.coupon?.code === coupon?.code && (
                                          <Button
                                            className="text-decoration-underline extra-small text-dark ps-3"
                                            label={t('cart.summary.coupons.removeCoupon')}
                                            // eslint-disable-next-line react/jsx-no-bind
                                            onClick={() => {
                                              removeCoupon();
                                            }}
                                            variant="blank"
                                          />
                                        )}
                                      </Div>
                                    </Div>
                                  ))}
                                </FormGroup>
                              )}
                            </PersonalCouponsFormHelper>
                          )}
                          <GenericCouponFormHelper>
                            {({ fieldsHelper }) => (
                              <FormGroup noMarginBottom {...fieldsHelper.code.formGroup} label={undefined}>
                                <InputGroup {...fieldsHelper.code.inputGroup}>
                                  <TextInput className="border-end-0 shadow-none" {...fieldsHelper.code.textInput} />
                                  <Button
                                    {...fieldsHelper.buttons.submit}
                                    className="border border-start-0 px-3 position-relative"
                                    variant="blank"
                                  />
                                </InputGroup>
                              </FormGroup>
                            )}
                          </GenericCouponFormHelper>
                        </AccordionItem>
                      </Accordion>
                    </Div>
                    {info?.paymentMethods?.includes('GIFT_CARD') && (
                      <>
                        <Div className="cart-gift-card border-bottom" marginBottom={3} paddingBottom={3}>
                          <GiftCardForm />
                          {cart.depositPayments && cart.depositPayments.length > 0 && (
                            <Div marginTop={3}>
                              {cart.depositPayments.map((depositPayment) => (
                                <Div display="flex" justifyContent="spaceBetween" key={depositPayment.code}>
                                  <small>
                                    <span className="gift-card-label">
                                      {depositPayment.type === 'GIFT' && `${t('misc.giftCard.label')}: `}
                                    </span>
                                    <span className="gift-card-code">{depositPayment.code}</span>
                                  </small>
                                  <Div display="flex">
                                    <small>{formatCurrency(depositPayment.value)}</small>
                                    <Button
                                      className="text-decoration-underline extra-small text-dark ps-3"
                                      label={t('cart.summary.coupons.removeCoupon')}
                                      // eslint-disable-next-line react/jsx-no-bind
                                      onClick={() => {
                                        removeGiftCard(depositPayment.code);
                                      }}
                                      variant="blank"
                                    />
                                  </Div>
                                </Div>
                              ))}
                            </Div>
                          )}
                        </Div>
                      </>
                    )}
                    <Div className="cart-shipping" marginBottom={3}>
                      <CartShippingProfilesFormHelper cart={cart}>
                        {({ fieldsHelper }) => (
                          <>
                            <FormGroup
                              {...fieldsHelper.id.formGroup}
                              label={t('cart.summary.shippingProfiles.title')}
                              labelClassName={'fw-bold'}
                              omitForAttribute
                            >
                              {fieldsHelper.id.formFields.map(({ radio, shippingProfile }) => (
                                <Radio
                                  key={radio.id}
                                  {...radio}
                                  label={
                                    <Div display="flex" justifyContent="spaceBetween">
                                      <Span>{shippingProfile.name}</Span>
                                      <Span>
                                        {formatCurrency(shippingProfile.cost, {
                                          showLabelIfZero: true,
                                        })}
                                      </Span>
                                    </Div>
                                  }
                                  labelClassName={'d-block'}
                                />
                              ))}
                            </FormGroup>
                          </>
                        )}
                      </CartShippingProfilesFormHelper>
                      {info?.shippingCountries && info.shippingCountries.length > 1 && (
                        <Div display={'flex'} justifyContent={'spaceBetween'}>
                          <div>{`${t('cart.summary.shippingCountry.title')}: ${shippingCountry?.name}`}</div>
                          <div>
                            <Button
                              label={t('cart.summary.shippingCountry.edit')}
                              onClick={handleChangeShippingCountryButtonClick}
                              variant="blank"
                            />
                          </div>
                        </Div>
                      )}
                    </Div>
                    {cart.codShippingCost > 0 && (
                      <Div
                        className="cart-additional-cost"
                        dynamicPadding={{ top: 3 }}
                        marginBottom={3}
                        uiClassName={{ bs5: 'border-top' }}
                      >
                        <H6 dynamicMargin={{ bottom: 2 }}>{t('cart.summary.additional.title')}</H6>
                        <Div display="flex" justifyContent="spaceBetween">
                          <Span>{t('cart.summary.additional.cashOnDelivery')}</Span>
                          <Span>{formatCurrency(cart.codShippingCost)}</Span>
                        </Div>
                      </Div>
                    )}
                    <Div className="cart-total" paddingY={3} uiClassName={{ bs5: 'border-top border-bottom' }}>
                      <Div display={'flex'} flexDirection={'column'} gap={0}>
                        {cart.productsCrossedOutPrice !== cart.productsCost && (
                          <>
                            <Div display="flex" justifyContent={'spaceBetween'}>
                              <Div>{t('cart.summary.totalProductsCost')}</Div>
                              <Div>{formatCurrency(cart.productsCrossedOutPrice)}</Div>
                            </Div>
                            {cart.totalDiscount > 0 && (
                              <Div display="flex" justifyContent={'spaceBetween'}>
                                <Div>{t('cart.summary.totalDiscount')}</Div>
                                <Div>-{formatCurrency(cart.totalDiscount)}</Div>
                              </Div>
                            )}
                          </>
                        )}
                        <Div display="flex" justifyContent={'spaceBetween'} marginBottom={3}>
                          <Div>{t('cart.summary.subtotal')}</Div>
                          <Div>{formatCurrency(cart.productsCost)}</Div>
                        </Div>
                        <Div display="flex" justifyContent={'spaceBetween'}>
                          <Div>{t('cart.summary.shippingProfiles.title')}</Div>
                          <Div>
                            {cart.shippingCost > 0 ? formatCurrency(cart.shippingCost) : t('cart.summary.freeShipping')}
                          </Div>
                        </Div>
                        {cart.totalDepositPayments > 0 && (
                          <Div display="flex" justifyContent={'spaceBetween'}>
                            <Div>{t('cart.summary.giftCard')}</Div>
                            <Div>-{formatCurrency(cart.totalDepositPayments)}</Div>
                          </Div>
                        )}
                      </Div>
                    </Div>
                    <Div className="cart-total" paddingY={3}>
                      <Div className="total-to-pay" display="flex" fontWeight={700} justifyContent={'spaceBetween'}>
                        <Div>{t('cart.summary.totalCost')}</Div>
                        <Div>{formatCurrency(cart.totalCost)}</Div>
                      </Div>
                    </Div>
                    <Div display="grid">
                      {cart.alerts
                        ?.filter((alert) => alert.type === 'CART')
                        .map((alert) => (
                          <AlertFromDto alert={alert} key={alert.code} light small />
                        ))}
                      <Link
                        className={classnames('btn btn-primary', {
                          disabled: !canMoveOn,
                        })}
                        to="/checkout"
                      >
                        {t('cart.summary.proocedToCheckout')}
                      </Link>
                      {appConfig.installmentPaymentBanner && (
                        <Div marginTop={3}>
                          <InstallmentPaymentBanner amount={cart.totalCost} />
                        </Div>
                      )}
                    </Div>
                    {blockingAlerts && blockingAlerts.length > 0 && (
                      <Div marginTop={3}>
                        {blockingAlerts.map((alert) => (
                          <AlertFromDto alert={alert} className="extra-small" key={alert.code} noMargin />
                        ))}
                      </Div>
                    )}
                  </Div>
                </Div>
              </Col>
              <Col display={{ md: 'none' }} size={12}>
                <Div background="white" className="cart-footer" marginBottom={3} padding={3}>
                  <H6>{t('cart.paymentMethods.title')}</H6>
                  <PaymentIcons width={50} />
                </Div>
              </Col>
            </>
          ) : (
            <Col size={12}>
              <Div background="white" padding={5} textAlign="center">
                <H3>{t('cart.empty.title')}</H3>
                <P dynamicMargin={0}>{t('cart.empty.description')}</P>
                <Link className={classnames('btn btn-primary mt-3 mt-md-5')} to="/search">
                  {t('misc.discoverOurProducts')}
                </Link>
              </Div>
            </Col>
          )}
        </Row>
      </Container>
    ),
  });
};

interface ChangeShippingCountryModalProps {}

const ChangeShippingCountryModal = (props: ChangeShippingCountryModalProps) => {
  return (
    <>
      <LocaleForm />
      <div className="text-center small pt-3">{t('cart.modal.shippingCountry.alert')}</div>
    </>
  );
};
